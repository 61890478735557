@media screen and (max-width: 364px) {

  p, ul li {
    font-size: 16px;
  }

  h4 {
    font-size: 1rem !important;
  }

/* FRONT PAGE STUFF *********************************************/

  .hero-wrapper {
    height: calc(100vh - 64px);
  }

  #heroimage {
    height: calc(100vh - 40px);
  }

  .hero-form {
    height: 188px;
  }
  .hero-form h2 {
    font-size: 30px;
  }

.page-content {
 /* margin-top:24px;*/
}



  #highlights h2,
  #FPHelp h2 {
    font-size: 46px;
  }

  .fp-help-item p {
    font-size: 19px;
  }

  .fp-syn-item h2 {
    font-size: 29px;
    top: 8%;
    left: 35%;
  }

  .fp-syn-container-sub { 
    width: initial;
    padding:20px;
  }
  
  .summary-container {

  }

.fp-help-intro {
  width:92%;
}

   /* MAP ****************************************/

  .route-container {
    padding: 12px;
  }

  .item {
    margin: 0 0.4% 10px 0.4%;
    width: 47%;
  }

  .map-wrapper {
   /* z-index: 1;*/
  }

  .tabs {
    z-index: 10;
  }

  .paneA,
  .paneB {
    z-index: 10020000;
  }

  .container-wrapper {
    transition: height 1s;
  }

  .wrapper {
    padding-top: 40px;
  }

  .wrapper-special {
    height: 100vh;
    max-height: -webkit-fill-available;
  } 

  .login-container {
    height: 52px;
    width: 56px;
  }

  .from-container {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    margin-left: 0;
  }

  .from-container h5 {
    display: block;
  }

  .from-container-icon {
    width: 7%;
    height: 30px;
  }

  #from-form {
    width: 100%;
  }

  input#searchsubmit {
    position: absolute;
    right: 16px;
  }

 .drawtools-ttips {
    width: 57%;
  }

  .searchtools-help h3 {
    font-size: 13px;
  }

  .searchtools-help p {
    font-size: 11px;
  }

  #setgethome {
    margin-top: 59px;
  }

  #counting {
    padding: 43px 0 19px 0;
  }

  #counting h1 {
    line-height: 1.7;
    padding: 0px 14px;
  }

  .datafield {
    width: 86%;
  }

  .datafield h5 {
    display: block;
  }

  .directions-container {
    position: absolute;
    bottom: 8px;
    left: 7px;
  }

  .route-input {
    box-shadow: none;
    margin-top: 48px;
  }

  .linear-loader {

    margin-left:0;
    top:-9px;
  }

  .item-leftcol {
    width: 100%;
  }

  .item-rightcol {
    width: 100%;
  }

  .item-header-right {
    position: initial;
  }

  .menu-item-outerright {
    border-left: 1px solid #5a5858;
  }

  .rightmenu a {
    margin: 0 19px;
  }

  .pane-header {
    width: 100%;
  }

  .item-main {
    display: block;
    width: 100%;
  }

  .item-image {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  .item-content {
    width: 100%;
    display: block;
    padding: 0;
  }
  .small {
    width: 88%;
  }

  .hl-container h3 {
    margin-left: 0;
    font-size: 30px;
  }
  .hl-container span {
    margin-left: 0;
    font-size: 23px;
    display: block;
  }

  /* user page */

  .db-left-pane-container {
    display: block;
    width: 100%;
  }

  .db-right-pane-container {
    display: block;
    width: 100%;
  }

  .db-trip-header h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .db-trip-header.activetrip h4 {
    font-size: 20px;
  }

  .btn-group-trips {
    position: initial;
  }

  .mem-content {
    width: 100%;
  }

  .mem-img {
    width: 100%;
  }

  .mem-btns {
    right: 20px;
  }

  .filters-activity {
    width: 100%;
  }

  .filters-category {
    width: 100%;
    margin-top: 8px;
  }

  .tools-loadmore {
    margin-right: 0;
    position: absolute;

    right: 0;
  }

  .tstbtn.loadmore.radiusleft {
    -webkit-border-radius: 0;
    -moz-border: 0;
    border-radius: 0;
    height: 40px;
  }

  .tstbtn.reset-btn.radiusright {
    -webkit-border-radius: 0;
    -moz-border: 0;
    border-radius: 0;
    height: 40px;
  }

  #head-1,
  #head-2 {
    display: none;
  }

  #notfound-container {
    width: 100%;
  }

  .notfound-btn {
    width: 40%;
    height: 116px;
  }

  .leaflet-popup-content-wrapper {
    width: 160px;
  }
}

/* header */

@media screen and (max-width: 630px) {
  .filter-input {
    height: 75px;
  }

  .plain {
    display: block;
    margin-bottom: 10px;
  }

  .fp-help-container-large {
    margin-top: 24px;
    width: 92%;
  }

  .fp-help-item {
    width: 100%;
  }

  .fphlp-btn {
    position: inherit;
    margin-top: 16px;
  }
}
