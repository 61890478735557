@media screen and (min-width: 500px) and (max-width: 989px) {
  p,
  ul li {
    font-size: 17px;
  }

  input#searchsubmit {
    position: absolute;
    right: 16px;
  }


  .wrapper-special {
    height: 100vh;
    max-height: -webkit-fill-available;
  } 

  /* FRONT PAGE STUFF ***************************************/

  #hero-form-container {
    display: inline-block;
  }

  .hero-inputactivity {
    width: 35%;
    margin-right: 1%;
    display: inline-block;
  }

  .css-uqexjp-control {
    -webkit-border-bottom-left-radius: 0 !important;
    -moz-border-radius-bottomleft: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .hero-inputplace {
    display: inline-block;
    width: 64%;
    margin: 0;
  }

  .hero-inputsubmit {
    width: 100%;
    margin-top: 8px;
  }

  input#heroinputfield {
    width: 100%;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    border-top-right-radius: 10px;
    padding: 0 2%;
    width: 96%;
  }

  input#herosubmit {
    width: 100%;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topright: 0;
    border-top-right-radius: 0;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-left-radius: 10px;
  }

  .hero-form {
    top: 50%;
    height: 214px;
  }

  #highlights h2,
  #FPHelp h2 {
    font-size: 46px;
  }

  .wa-features {
    padding: 0;
  }

  .fp-help-item p {
    /* font-size: 19px;*/
  }

  .fp-syn-item h2 {
    /*  font-size: 29px;*/
    top: 8%;
    left: 35%;
  }

  .fp-syn-container-sub {
    width: initial;
    padding: 20px;
  }

  .pane-header {
    width: 100%;
  }

  .container-wrapper {
    height: calc(100vh - 163px);
  }

  .to-container {
    display: inherit;
    width: 386px;
  }

  .fp-help-intro {
    width:92%;
  }


  .fphlp-btn {
    position: inherit;
    margin-top: 16px;
  }




  .directions-container {
    position: absolute;
    right: 15px;
    top: 13px;
  }

  .datafield h5 {
    margin-right: 30px;
  }

  .route-input {
    height: 80px;
    box-shadow: none;
    margin-top: 61px;
  }

  .linear-loader {
    margin-left: 0;
    top: -9px;
  }

  .from-container {
    margin: 0 0 8px 0;
    width: 100%;
  }

  input#searchsubmit {
    margin-left: 9px;
  }

  .to-container {
    margin: 0;
  }

  #setgethome {
    cursor: pointer;
    vertical-align: top;
    margin-top: 20px;
    float: right;
  }

  #whatabout {
    /*padding: 46px 0;*/
  }

  .small {
    width: 92%;
  }

  .fp-help-container-large {
    width: 100%;
  }

  .item {
    margin: 0 0.4% 10px 0.4%;
    width: 32%;
  }

  /* user page */

  .left-pane,
  .right-pane {
    display: block;
    width: 100%;
  }

  .db-left-pane-container {
    display: block;
    width: 100%;
  }

  .db-right-pane-container {
    display: block;
    width: 100%;
  }

  .db-trip-header h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .db-trip-header.activetrip h4 {
    font-size: 20px;
  }

  .btn-group-trips {
  }

  .hero-form h2 {
    font-size: 37px;
  }

  .filters-category,
  .filters-activity {
    width: 49%;
  }

  .filters-category {
    float: right;
    margin-right: 0;
  }

  .tools-loadmore {
    margin-right: 0;
    position: absolute;
    right: 0;
  }

  .tstbtn.loadmore.radiusleft {
    -webkit-border-radius: 0;
    -moz-border: 0;
    border-radius: 0;
    height: 40px;
  }

  .tstbtn.reset-btn.radiusright {
    -webkit-border-radius: 0;
    -moz-border: 0;
    border-radius: 0;
    height: 40px;
  }

  .paneA,
  .paneB {
    z-index: 10020000;
  }
}
