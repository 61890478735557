/* exception query for hero form  RESTORE WHEN ADDING ACTIVITY FIELD*/
@media screen and (max-width: 700px) {
    .route-container {
      padding: 12px;
    }
  
    .hero-form {
      height: 188px;
    }
  
    #hero-form-container {
      display: block;
    }
  
    .hero-inputsubmit {
      width: 100%;
      margin-top: 8px;
    }
  
    .css-uqexjp-control {
      -webkit-border-top-right-radius: 10px !important;
      -webkit-border-bottom-left-radius: 0 !important;
      -moz-border-radius-topright: 10px !important;
      -moz-border-radius-bottomleft: 0 !important;
      border-top-right-radius: 10px !important;
      border-bottom-left-radius: 0 !important;
    }
  
    .hero-inputactivity {
      width: 100%;
      margin-bottom: 10px;
      display: block;
    }
  
    .hero-inputplace {
      display: initial;
      width: 100%;
    }
  
    input#heroinputfield {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      padding: 0 4%;
      width: 92%;
      font-size: 18px;
    }
  
    .css-1ae1i0f-placeholder {
      font-size: 18px !important;
    }
  
    input#herosubmit {
      width: 100%;
      -webkit-border-top-right-radius: 0;
      -moz-border-radius-topright: 0;
      border-top-right-radius: 0;
      -webkit-border-bottom-left-radius: 10px;
      -moz-border-radius-bottomleft: 10px;
      border-bottom-left-radius: 10px;
    }
  
    #head-1,
    #head-2 {
      display: none;
    }
  }