/* fonts */

@font-face {
  font-family: "PlayfairDisplay";
  src: local("PlayfairDisplay"),
    url("./assets/fonts/playfairdisplay/playfairdisplay.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/playfairdisplay/playfairdisplay.woff2") format("woff2"),
    url("./assets/fonts/playfairdisplay/playfairdisplay.woff") format("woff"),
    url("./assets/fonts/playfairdisplay/playfairdisplay.ttf") format("truetype"),
    url("./assets/fonts/playfairdisplay/playfairdisplay.svg#PlayfairDisplay") format("svg");
}

@font-face {
  font-family: "Ember-Light";
  src: local("Ember-Light"),
    url("./assets/fonts/emberlight/AmazonEmber_Lt.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/emberlight/AmazonEmber_Lt.woff2") format("woff2"),
    url("./assets/fonts/emberlight/AmazonEmber_Lt.woff") format("woff"),
    url("./assets/fonts/emberlight/AmazonEmber_Lt.ttf") format("truetype"),
    url("./assets/fonts/emberlight/AmazonEmber_Lt.svg") format("svg");
}

@font-face {
  font-family: "BentonSansRegular";
  src: local("BentonSansRegular"),
    url("./assets/fonts/BentonSansRegular/BentonSansRegular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/BentonSansRegular/BentonSansRegular.woff2") format("woff2"),
    url("./assets/fonts/BentonSansRegular/BentonSansRegular.woff") format("woff"),
    url("./assets/fonts/BentonSansRegular/BentonSansRegular.ttf") format("truetype"),
    url("./assets/fonts/BentonSansRegular/BentonSansRegular.svg") format("svg");
}

@font-face {
  font-family: "latoregular";
  src: local("latoregular"),
    url("./assets/fonts/Latoregular/latoregular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Latoregular/latoregular.woff2") format("woff2"),
    url("./assets/fonts/Latoregular/latoregular.woff") format("woff"),
    url("./assets/fonts/Latoregular/latoregular.ttf") format("truetype"),
    url("./assets/fonts/Latoregular/latoregular.svg") format("svg");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("./assets/fonts/Roboto-Regular/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Roboto-Regular/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular/Roboto-Regular.woff") format("woff"),
    url("./assets/fonts/Roboto-Regular/Roboto-Regular.ttf") format("truetype"),
    url("./assets/fonts/Roboto-Regular/Roboto-Regular.svg") format("svg");
}

@font-face {
  font-family: "Magra-Bold";
  src: local("Magra-Bold"),
    url("./assets/fonts/Magra-Bold/Magra-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Magra-Bold/Magra-Bold.woff2") format("woff2"),
    url("./assets/fonts/Magra-Bold/Magra-Bold.woff") format("woff"),
    url("./assets/fonts/Magra-Bold/Magra-Bold.ttf") format("truetype"),
    url("./assets/fonts/Magra-Bold/Magra-Bold.svg") format("svg");
}

@font-face {
  font-family: "Mada-Regular";
  src: local("Mada-Regular"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.woff2") format("woff2"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.woff") format("woff"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.ttf") format("truetype"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.svg") format("svg");
}

@font-face {
  font-family: "Mada-SemiBold";
  src: local("Mada-SemiBold"),
    url("./assets/fonts/Mada-SemiBold/Mada-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Mada-SemiBold/Mada-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Mada-SemiBold/Mada-SemiBold.woff") format("woff"),
    url("./assets/fonts/Mada-SemiBold/Mada-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Mada-SemiBold/Mada-SemiBold.svg") format("svg");
}

@font-face {
  font-family: "Blinker-Regular";
  src: local("Blinker-Regular"),
    url("./assets/fonts/Blinker-Regular/Blinker-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Blinker-Regular/Blinker-Regular.woff2") format("woff2"),
    url("./assets/fonts/Blinker-Regular/Blinker-Regular.woff") format("woff"),
    url("./assets/fonts/Blinker-Regular/Blinker-Regular.ttf") format("truetype"),
    url("./assets/fonts/Blinker-Regular/Blinker-Regular.svg") format("svg");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
    url("./assets/fonts/OpenSans-Regular/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/OpenSans-Regular/OpenSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/OpenSans-Regular/OpenSans-Regular.woff") format("woff"),
    url("./assets/fonts/OpenSans-Regular/OpenSans-Regular.ttf") format("truetype"),
    url("./assets/fonts/OpenSans-Regular/OpenSans-Regular.svg") format("svg");
}

@font-face {
  font-family: "Mada-Regular";
  src: local("Mada-Regular"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.woff2") format("woff2"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.woff") format("woff"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.ttf") format("truetype"),
    url("./assets/fonts/Mada-Regular/Mada-Regular.svg") format("svg");
}

/* TYPOGRAPHY */

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  font-family: "Mada-Regular", sans-serif;
  color: #000;
}

p {
  font-family: "Mada-Regular", sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  margin: 0;
  color: #2d2c2c;
}

blockquote {
  font-size: 21px;
  font-weight: 500;
  color: #706d6d;
  background-color: #f6f4f4;
  padding: 16px;
  border-radius: 16px;
}

.shift {
  background-color: #fff;
  padding: 46px 3%;
  border-radius: 10px;
  margin-top: 252px !important;
}

.notification {
  color: #943535;
}

#single-article {
  /*background:  url('./assets/heroimg.jpg') no-repeat;*/
}

#single-article img.block-img {
  margin: 40px 20%;
}

#single-article img.inline-img {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 6px;
  margin-left: 10px;
}

#single-article h2 {
  color: #059ed7;
  font-size: 2rem;
  padding: 20px 0 15px 0;
  /*	border-bottom: 1px solid #b7b5b5;*/
}

#single-article h3 {
  font-size: 1.6rem;
  margin: 11px 0 8px 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #696868;
}

#single-article h4 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 10px 0 5px 0;
  color: #7c3c3c;
}

h1 {
  font-family: "Magra-Bold", sans-serif;
}

h2 {
  font-family: "Magra-Bold", sans-serif;
  font-size: 1.7rem;
  letter-spacing: 0.3px;
}

h3 {
  font-family: "Mada-SemiBold", sans-serif;
  font-size: 1.3rem;
}

h4 {
  line-height: 1.4rem;
}

label h4 {
  padding-top: 10px;
  font-weight: bold;
}

h5 {
  color: #414040;
  padding: 0;
}

h6 {
  font-family: "Mada-SemiBold", sans-serif;
}

hr {
  margin: 0;
}

/* HEADER */

ul.nav a {
  color: #fff;
}

ul {
  list-style-type: none;
}

.nav.main {
  float: left;
}

.nav.login {
  float: right;
}

img {
  max-width: 100%;
}

.header {
  position: fixed;
  z-index: 1000;
  height: 64px;
  width: 100%;
  background: linear-gradient(270deg, #000000 1.33%, #005d80 51.17%, #000000 100%);
}

.login-section {
  position: absolute;
  /*height:64px;*/
  right: 0;
  cursor: pointer;
}

.login-section p {
  position: absolute;
  right: 70px;
  transform: translate(-50%, -50%);
  top: 50%;
  color: #fff;
}

.login-container {
  margin: 0;
  background-color: #e5ff44;
  height: 64px;
  width: 80px;
  position: relative;
  display: inline-block;
}

.login-container img {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translate(-50%, 0);
}

.menu-container {
  display: inline-block;
  background-color: #efeb00;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  height: 64px;
  width: 80px;
  transition: background-color 0.5s;
}

.menu-container:hover {
  background-color: #bab705;
}

.btn-large {
  position: absolute;
  color: #000;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#user-menu {
  display: none;
  position: absolute;
  background-color: #000;
  width: 164px;
  right: 0px;
  top: 64px;
  padding: 0px;
  z-index: 1000000;
}

#user-menu a {
  color: #fff;
}

#user-menu a:hover {
  color: #e5ff44;
}

.menu-container:hover #user-menu {
  display: block;
}

.columns {
  margin-left: 0 !important;
}

.column:first-child,
.columns:first-child {
  margin-left: 0 !important;
}

.logo-container {
  position: relative;
}

.logo {
  height: 42px;
  width: 308px;
  background: url("./assets/silencespotterlogo.png") no-repeat;
  margin-top: 12px;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
}

.leftmenu {
  float: left;
  height: 100%;
}

.leftmenu a {
  text-transform: uppercase;
  margin: 0 20px;
}

.rightmenu {
  text-align: left;
  height: 100%;
  position: absolute;
  right: 26px;
}

.rightmenu a {
  margin: 0 7px;
}

.fa-user {
  color: #000;
  font-size: 22px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.menu-item-outerleft {
  display: inline-block;
  height: 100%;
}

.menu-item-outerright {
  display: inline-block;
  height: 100%;
}

.menu-item {
  display: inline-block;
  padding-top: 24px;
}

.sub {
  padding: 14px 25px;
  width: 100%;
}

.sub:hover {
  color: #e5ff44;
  background-color: #313030;
}

.menu-item {
  color: #fff;
}

.menu-item:hover {
  color: #e5ff44;
}

#hamburger-item,
#login-item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.icon-solid {
  padding-right: 6px;
}

a {
  color: #0ab6f5;
  text-decoration: none;
}

a:active,
a:hover,
a:focus {
  color: #60d2fd;
  text-decoration: none;
}

.wrapper-container {
  position: relative;
  min-height: 100%;
  background-color: #f7f7f7;
}

.wrapper {
  background-color: #f7f7f7;
  padding-top: 64px;
  padding-bottom: 76px;
}

.wrapper-img {
  width: 100%;
  height: 250px;
  position: relative;
  z-index: 0;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  /*filter: grayscale(80%);*/
}

.wrapper-img h1 {
  color: #fff;
  position: absolute;
  bottom: 83px;
  left: 18%;
  letter-spacing: 1px;
}

.page-container {
  padding-bottom: 72px;
}

.footer-inner {
  width: 1300px;
  margin: 0 auto;
  padding-top: 25px;
}

.footer-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #1f1e1e;
  height: 78px;
}

.footer-container {
  /*margin: 0 auto; */
  padding-top: 24px;
  /*max-width: 1300px;	*/
  /*width: 84%;*/
  color: #ccc5c5;
}

.footer-inner-left {
  text-align: left;
  float: left;
}

.footer-inner-left a p {
  display: inline-block;
  font-size: 15px;
  margin-left: 14px;
  margin-right: 14px;
}

.footer-center {
  /*position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  width: 80%;*/
  text-align: center;
}

.footer-center p {
}

.footer-center img {
  margin-right: 10px;
  cursor: pointer;
}

.footer-center ul li {
  font-size: 15px;
  display: inline;
}

.footer-inner-right {
  text-align: right;
  float: right;
  padding-top: 4px;
}

/* Auth windows */

.auth-wrapper {
  top: 150px;
  position: relative;
  padding: 17px;
  transform: translateX(-50%);
  left: 50%;
  background-color: #fcfcfc;
  border-radius: 7px;
  border: 1px solid #a7e3fa;
  overflow: auto;
  max-width: 396px;
  -webkit-box-shadow: 0px 0px 22px -6px rgba(59, 59, 59, 0.47);
  box-shadow: 0px 0px 22px -6px rgba(59, 59, 59, 0.47);
}

.auth-wrapper form input {
  height: 40px;
}

.auth-wrapper legend {
  margin-bottom: 20px;
}

legend a h5 {
  color: #0ab6f5;
}

.login-wrapper {
  /*padding:  0 10px;*/
  height: 100vh;
}

/* UI ELEMENTS */

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  height: 32px;
  padding: 1px 5px;
  width: 95%;
  font-size: 17px;
  padding-left: 10px;
}

input[type="text"]:focus {
  outline: none;
}

input[type="submit"],
input[type="image"] {
  padding: 5px 0;
  background-color: #059ed7;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 600;
}

input[type="image"] {
  height: 22px;
}

input[type="submit"] {
}

input#loginsubmit,
input#registersubmit {
  border-radius: 7px;
  padding: 8px;
  border: 1px solid #6c6204;
}

input#searchsubmit {
  width: 36px;
  /*margin-left: 16px;*/
  background-color: transparent;
  /*float: right !important;*/
  position: inherit;
  right: 50px !important;
}

input#herosubmit {
  width: 86px;
  height: 42px;
  border-style: none;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  background-color: #059ed7;
  transition: background-color 0.4s;
}

input#herosubmit:hover {
  background-color: #034e6a;
}

input#heroinputfield {
  width: 100%;
  height: 40px;
  font-size: 20px;
  border-style: none;
  padding: 1px 2%;
}

/* TST button */

.tstbtn {
  margin: 0;
  border: none;
  padding: 2px 8px;
  vertical-align: top;
}

.tstbtn p {
  font-weight: bold;
  display: inline-block;
  font-size: 15px;
  vertical-align: text-bottom;
  padding: 4px 3px 3px 0;
  color: #fff;
  letter-spacing: 0.2px;
}

.loadmore {
  /* background-color: #059ed7;*/
  float: left;
  height: 36px;
  border: none;
}

.loadmore.enabled:active {
  background-color: #033143 !important;
}

.loadmore.enabled:hover {
  background-color: #0279a5;
}

.loadmore.disabled {
  background-color: #d5d2d2;
}

#notfound-container {
  width: 380px;
  margin: 0 auto;
  position: relative;
}

.notfound-btn {
  height: 150px;
  background-color: #004159;
  cursor: pointer;
  text-align: center;
  padding: 20px 10px 10px 10px;
  border: 1px solid #f2f604;
  border-radius: 5px;
  transition: background-color 0.2s;
  width: 150px;
}

.notfound-btn p {
  color: #f2f604;
  font-weight: bold;
}

.notfound-btn:hover {
  background-color: #21607b;
}

#notfound-btn-left {
  position: absolute;
  left: 0;
}

#notfound-btn-right {
  position: absolute;
  right: 0;
}

.reset-btn {
  height: 36px;
  background-color: #059ed7;
}

.reset-btn.disabled {
  background-color: #d5d2d2;
}

.reset-btn.enabled:active {
  background-color: #033143 !important;
}

.loadmore p img {
  vertical-align: bottom;
}

.tools-results {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  padding-top: 6px;
}

.medium {
  height: 34px;
  background-color: #059ed7;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
}

.radiusall {
  border-radius: 5px;
}

.secondary-btncolor {
  background-color: #0687b8;
}

#setgethome {
  cursor: pointer;
  vertical-align: top;
  margin-top: 4px;
  float: right;
}

/* HOME PAGE *************************************/

.main-content {
  max-width: 1869px;
  margin: 0 auto;
  padding-top: 80px;
}

.page-content {
  /*margin: 0 auto;
  border-radius: 10px;
  position: relative;*/
}

.wide {
  max-width: 100%;
}

.small {
  max-width: 1300px;
  margin: 0 auto;
  /*width: 84%;*/
  width: 90%;
}

.vsmall {
  max-width: 883px;
  margin: 0 auto;
  width: 95%;
}

/*.small h1, .vsmall h1 {
	position: absolute;
	color: #fff;
	top: -65px;
	letter-spacing: .5px;
	font-size: 55px;
}*/

.small h1 {
  left: 30px;
}

.vsmall h1 {
  left: 30px;
}

.hero-wrapper {
  position: relative;
  height: calc(100vh - 64px);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #090a1a;
  z-index: 100;
}

#heroimage {
  max-width: 100vw;
  object-fit: cover;
  object-position: 50%;
  height: calc(100vh - 64px);
  width: 100vw;
}

.hero-left {
  width: 50%;
  display: inline-block;
}

.hero-right {
  width: 50%;
  display: inline-block;
  float: right;
  font-size: 3.4rem;
  color: #fff;
  margin-top: 136px;
}

/*.hero-form { 
	
	max-width: 450px;
	width: 87%;
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	z-index: 100;	
	background: rgba(0, 14, 19, 0.77);
	border: 1px solid #106DCA;
	box-sizing: border-box;
	border-radius: 10px;
	height: 214px;
}

.hero-form h2 {
	font-size: 46px;
	color: #fff;
	margin-bottom: 4px;
	width: 100%;
	text-align: center;
}

#hero-form-container {
	position: absolute;
	left: 50%;
	width: 84%;
	display: flex;
	margin-top: 10px;
	transform: translate(-50%);
}*/

/*update this when activity field added */

.hero-form {
  background: rgba(0, 14, 19, 0.6);
  border: 1px solid #106dca;
  border-radius: 10px;
  box-sizing: border-box;
  height: 176px;
  left: 50%;
  max-width: 960px;
  position: absolute;
  top: 26%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 87%;
  z-index: 100;
}

.hero-form h2 {
  font-size: 46px;
  color: #fff;
  margin-bottom: 4px;
  width: 100%;
}

#hero-form-container {
  position: absolute;
  left: 50%;
  width: 84%;
  display: flex;
  margin-top: 10px;
  transform: translate(-50%);
}

.route-container p {
  position: absolute;
  bottom: 32px;
  color: #ebff01;
  font-size: 15px;
  left: 37%;
  z-index: -1;
  padding-bottom: 0;
}

.hero-infobar {
  z-index: 1000000;
  position: absolute;
  color: #fff;
  font-size: 15px;
  bottom: 0;
  right: 0;
  padding: 6px 16px;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  cursor: pointer;
  transition: background-color 0.5s;
}

.hero-infobar:hover {
  background-color: rgba(20, 20, 20, 0.7);
}

.hero-infobar img {
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
}

.hero-infobar span {
  color: #059ed7;
}

#head-1 {
  color: #d4d105;
  letter-spacing: 0.3px;
}

#head-2 {
  font-size: 30px;
  letter-spacing: 0.6px;
}

#head-2 span {
  font-size: 18px;
}

.hero-inputactivity {
  width: 50%;
  margin-right: 1%;
}

.hero-inputplace {
  width: 80%;
  margin-right: 3%;
}

.hero-inputsubmit {
  margin-top: 37px;
}

/* WHAT ABOUT / SUMMARY ********************/

/* FADE UP TEST */

#fadeupcontent {
  background-color: #1f1e1e;
  padding: 66px 0;
}

.fp-syn-item {
}

.fp-syn-item h1 {
  color: #e8e50e;
  letter-spacing: 0.5px;
}

.fp-syn-item h2 {
  font-size: 42px;
}

.fp-syn-item h3 {
  font-size: 35px;
}

.fp-syn-item h2,
.fp-syn-item h3 {
  color: #e8e50e;
  font-family: "Mada-SemiBold", sans-serif;

  letter-spacing: 0.1px;

  background: #1c72f4; /* Old browsers */
  background: -moz-linear-gradient(left, #1c72f4 0%, #e8e50e 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #1c72f4 0%, #e8e50e 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #1c72f4 0%, #e8e50e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c72f4', endColorstr='#e8e50e',GradientType=1 ); /* IE6-9 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*.fade-in-section {
  opacity: 0;
  transform: translate(0, 30%);
  visibility: hidden;
  
  will-change: opacity, visibility;
}*/

/*.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
  display: inline-block;
}*/

/*.fade-in-content {
  transform: translate(0, 0);
  width: 20%;
  height: 124px;
  background-color: #012a39;
  border-radius: 20px;
  border: 1px solid #e8e50e;
  padding: 26px 2%;
}*/

/*.fade-in-content a {
  color: #079bd2;
}

.fade-in-content h4 {
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

#tsparticles {
  position: absolute;
  height: 100%;
  width: 100%;
}*/

#whatabout {
  background-color: #1f1e1e;
 
  position: relative;
}

/*.summary-test {
  position: absolute;
  top: 30px;
  width: 100%;
  height: 200px;
  background-color: #004159bd;
}*/

.summary-container {
  /*background-color: #0b465c;*/
  border-radius: 15px;
  padding: 60px 0 45px 0;
}

.fp-syn-container {
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
}

.fp-syn-container ul {
  list-style-type: none;
  padding-top: 15px;
}

.fp-syn-container ul li {
  display: flex;
}

.fp-syn-container ul li p {
  color: #fff;
  padding-top: 6px;
}

.fp-syn-container ul li span {
  margin-right: 20px;
}

.fp-syn-container-sub {
  background-color: #013143c2;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 10px;
  width: 60%;
}

.fp-syn-container-sub ul {
  display: block;
  padding: 0;
}

.fp-syn-container-sub ul li {
  display: inline-block;
  background-color: #02455f;
  padding: 0 20px;
  margin-right: 6px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.8px;
  margin-top: 7px;
  color: #fff;
padding: 6px 14px;
}

.fp-syn-container-sub h2 {
  color: #1c72f4;
  margin-bottom: 10px;
}

.fp-syn-item-bullet {
  width: 10px;
  height: 10px;
  background-color: #1f1e1e;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #e8e50e;
}

.fp-syn-readmorebtn {
  background-color: #e8e50e;
  border: none;
  width: 100%;
  max-width: 284px;
  margin: 20px 0px;
  border-radius: 8px;
}

.fp-syn-readmorebtn p {
  font-size: 22px;
  font-weight: bold;
  padding: 9px 10px 8px 9px;
  display: inline-block;
  color: #000;
}

.fp-syn-readmorebtn span {
  font-size: 26px;
  color: #000;
  padding-left: 11px;
  vertical-align: middle;
}

.fp-syn-readmorebtn:hover {
  background-color: #a6a406;
}

/* OLD STUFF SUMMARY*/

.fp-syn-icon {
  position: absolute;
  left: 0;
  top: 17%;
  width: 80px;
  height: 80px;
  border: 1px solid #e8e50e;
  background-color: #012a39;
  border-radius: 46px;
  z-index: 10;
}

.fp-syn-icon img {
  width: 53px;
  height: 53px;
  padding: 13px 0 0 13px;
}

.fp-syn-tipcontent {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, 0);
  width: 47%;
  height: 124px;
  background-color: #012a39;
  border-radius: 20px;
  border: 1px solid #e8e50e;
  padding: 26px 2%;
}

.fp-syn-tipcontent a {
  color: #079bd2;
}

.fp-syn-tipcontent h4 {
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

/* HIGHLIGHTS *********************/

#highlights {
  background: linear-gradient(180deg, #cdedf8 0%, rgba(255, 255, 249, 0) 100%);
}

#highlights h2 {
  font-family: "Magra-Bold", sans-serif;
  color: #000;
  font-size: 59px;
  padding: 65px 0 11px 0;
}

.page-content-title {
  text-align: center;
}

.hl-container h3 {
  font-family: "Magra-Bold", sans-serif;
  font-size: 46px;
  color: #079bd2;
  cursor: pointer;
  margin-left: 76px;
  margin-top: 46px;
}

.hl-container h3:hover {
  color: #034e6a;
}

.hl-container span {
  color: #1f1e1e;
  font-family: "BentonSansRegular", sans-serif;
  font-size: 27px;
  margin-left: 13px;
}

.hl-row {
}

.hl-row-items {
  display: flex;
  margin: 8px 0 25px 0;
}

.hl-item {
  position: relative;
  cursor: pointer;
  height: 196px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  transition-property: box-shadow;
  transition-duration: 0.3s;
}

.hl-item:hover {
  -webkit-box-shadow: 0px 0px 21px -7px rgba(18, 16, 18, 1);
  -moz-box-shadow: 0px 0px 21px -7px rgba(18, 16, 18, 1);
  box-shadow: 0px 0px 21px -7px rgba(18, 16, 18, 1);
}

.hl-item img {
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  opacity: 0.9;
}

.hl-item h4 {
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
}

#hl-0,
#hl-1,
#hl-2,
#hl-3,
#hl-4,
#hl-5 {
  margin-right: 25px;
}

.hl-getmorebtn-container {
  width: 252px;
  height: 252px;
  background-color: #d43333;
  border-radius: 126px;
  margin: 100px auto;
  position: relative;
}

.hl-getmorebtn-container img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 19%);
}

.hl-getmorebtn-container h5 {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -62%);
  text-align: center;
  color: #f2f604;
  font-size: 22px;
  font-weight: bold;
}

.styles-module_carousel-arrow__26sRw {
  width: 40px;
  height: 40px;
}

img.arrow-left,
img.arrow-right {
  margin-top: 50px;
  cursor: pointer;
}

/* Counting *************************/

#counting {
  background-color: #e8e8e6;
  padding: 100px 0 30px 0;
}

#counting h1 {
  text-align: center;
}

#counting h1 .count-value {
  font-family: "Mada-SemiBold", sans-serif;
  background-color: #055c7b;
  color: #fff;
  padding: 4px 12px;
  margin-left: 16px;
  margin-right: 16px;
}

#counting h1 .count-variable {
  color: #055c7b;
  margin-left: 4px;
  margin-right: 4px;
}

#counting p {
  text-align: center;
  margin-top: 40px;
  font-weight: bold;
}

/* FP Help *************************/

#FPHelp {
  background: #006084;
  /* Old browsers */
  background: -moz-linear-gradient(top, #006084 0%, #19191a 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #006084 0%, #19191a 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #006084 0%, #19191a 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006084', endColorstr='#19191A', GradientType=0);
  /* IE6-9 */
}

.fp-help-container {
  /* position: relative;*/
}

.fp-help-container-small {
  position: relative;
  width: 39%;
  display: inline-block;
}

.fp-help-container-large {
  position: relative;
  width: 52%;
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
}

#FPHelp h2 {
  font-family: "OpenSans-Regular", sans-serif;
  letter-spacing: 0.2px;
  color: #fff;
  text-align: left;
  font-size: 35px;
  padding: 0 0 25px 4%;
}

.fp-help-item {
  width: 100%;
  padding: 0px 0% 56px 4%;
  display: inline-block;
  vertical-align: top;
}

.fp-help-item h3 {
  color: #f2f604;
  font-family: "OpenSans-Regular", sans-serif;
  font-size: 26px;
  letter-spacing: 0.1px;
}

.fp-help-item p {
  font-family: "Ember-Light", sans-serif;
  color: #fff;
  font-size: 18px;
}

.fp-help-intro {
  /*width: 100%;*/
  display: inline-block;
  background-color: #034b65;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 18px 4% 54px 4%;
  position: relative;

  width: 40%;
  display: inline-block;
}

.fp-help-intro p {
  font-size: 16px;
  color: #e8e3e3;
  font-family: "Ember-Light", sans-serif;
}

.fp-help-intro p.signature {
  padding-top: 20px;
  font-family: "Blinker-Regular", sans-serif;
}

.fp-help-intro .profilepic {
  position: absolute;
  bottom: -5px;
  right: 4px;
}

.fphlp-btn {
  position: inherit;
  bottom: 0;
  background-color: #034e6a;
  transition: background-color 0.2s;
  border: 1px solid #f2f604;
  padding: 0 15px;
  height: 41px;

}

#fp-help-btn {
  background-color: #034e6a;
  transition: background-color 0.2s;
  border: 1px solid #f2f604;
  border-radius: 5px;
  color: #f2f604;
  font-size: 22px;
  font-weight: bold;
  padding: 0 10px !important;
  position: absolute;
  bottom: 0;
  display: block;
  height: 41px;
}

.fphlp-btn:hover,
#fp-help-btn:hover {
  background-color: transparent;
}

.fphlp-btn p {
  color: #f2f604;
  font-size: 22px;
  font-weight: bold;
  padding: 0 10px 3px 0;
}

.fphlp-btn span {
  color: #f2f604;
  font-size: 22px;
  vertical-align: text-bottom;
}

.help-features {
  border-radius: 15px;
  padding: 50px 0 86px 0;
}

#news-faqs {
  background-color: #fff;
}

#tab1,
#tab2 {
  border: none;
  border-right-color: currentcolor;
  border-right-style: none;
  border-right-width: medium;
  width: 50%;
  border-radius: 0;
  margin: 0 !important;
  font-size: 16px;
  color: #fff;
}

#tab1 {
  border-right: 1px solid #fff;
}

#tab1:hover,
#tab2:hover {
}

.nav {
  display: flex;
  margin: 0;
}

ul.nav li {
  list-style-type: none;
  margin-right: 20px;
}

.route-container {
  margin: 0 auto;
  padding: 24px;
}

.inputset {
  position: relative;
  margin-right: 20px;
  vertical-align: top;
}

.datafield {
  display: inline-block;
  vertical-align: top;
  /*width: 293px;*/
  width: 90%;
}

#datafield-from {
  border: none;
  display: inline-block;
  vertical-align: top;
  width: 64%;
}

#datafield-from:hover {
  border-bottom: 2px solid #000 !important;
}

input#datafield-from {
  background-color: transparent;
}

.from {
  position: relative;
}

#getsugglist {
  vertical-align: top;
  margin-top: 9px;
}

.plain {
  margin-right: 16px;
}

.datafield h5 {
  display: inline-block;
  margin-right: 11px;
  vertical-align: bottom;
  padding-bottom: 6px;
}

.from-container {
  display: inline-block;
  width: 386px;
  height: 35px;
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fbfafa;
  margin: 0 6px;
  vertical-align: top;
}

.from-container-icon {
  display: inline-block;
  vertical-align: top;
  padding: 5px 5px 0 5px;
  background-color: #eee;
  cursor: pointer;
}

.from-container-icon:hover {
  background-color: #cfcaca;
}

.from-container-homebtn {
  background-color: #059ed7;
  display: inline-block;
  vertical-align: top;
  padding: 5px 5px 1px 7px;
  margin-top: -1px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
}

.from-container-homebtn:hover {
  background-color: #034e6a;
}

.from-container h5 {
  display: inline-block;
  margin-right: 6px;
  vertical-align: bottom;
  padding-bottom: 11px;
}

.from-container-openfiltersbtn {
  background-color: #fbfafa;
  padding: 8px 8px 1px 6px;
  margin-top: -1px;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  float: right;
}

.from-container-openfiltersbtn:hover {
  background-color: #ebe7e7;
}

.to-container {
  display: inline-block;
  vertical-align: top;
  width: 351px;
  height: 35px;
  border: 1px solid #eee;
  border-radius: 16px;
  background-color: #fbfafa;
  margin: 0 6px;
}

.to-container #location {
  padding: 5px 0 0 5px;
}

.directions-container {
  display: inline-block;
  vertical-align: top;
}

.filters-activity {
  display: inline-block;
  position:relative;
 /* z-index:11;*/
  width: 300px;
  margin-right: 6px;
  vertical-align: top;
  transition: opacity .2s;
}

.filters-category {
  display: inline-block;
  position:relative;
 /* z-index:10;*/
  width: 300px;
  margin-right: 6px;
  vertical-align: top;
  transition: opacity .2s;

}

.filters-close {
  transition-delay: .4s;
}

.tools-loadmore {
  display: inline-block;
  vertical-align: top;
  /*margin-right: 6px;*/
}

.tools-layout {
  display: inline-block;
  vertical-align: top;
  float: right;
}

.todo-container {
  margin: 0 auto;
  width: 40%;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 44px;
  background-color: #eee;
  border: 1px solid #eee;
  width: 100%;
  z-index: 1000000;
}

.react-autosuggest__input {
  display: inline-block;
  width: 274px;
  height: 40px;
}

/* PLANNER PAGE */

.route-input {
  /*width: 100%;*/
  background-color: #fff;
  height: 34px;
  box-shadow: 0px 5px 7px -5px rgb(0 0 0 / 91%);
  position: relative;
  z-index: 990;
  padding: 13px;
  margin-top: 64px;
  transition: height .5s;
}

/*.route-input-overlay {
  width: 100%;
 
  position: absolute;
  background: #fff;
  z-index: 10000000;
  bottom: 53px;
  transition: height 0.5s;
}*/

.filter-input {
  width: 100%;
  background-color: #fff;
  height: 35px;
  box-shadow: 0px 5px 7px -5px rgb(0 0 0 / 91%);
  position: fixed;
  z-index: 900;
  padding: 13px;
}

.fixpos {
  margin-top: 80px;
}

#from-form {
  display: inline-block;
  vertical-align: top;
  margin-right: 7px;
  width: 100%;
}

.find {
  -webkit-border-top-right-radius: 7px;
  -webkit-border-bottom-right-radius: 7px;
  -moz-border-radius-topright: 7px;
  -moz-border-radius-bottomright: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.home {
  background-color: #e5ff44;
  height: 34px;
}

.inputfields {
  width: 68%;
  display: contents;
  margin: 0 !important;
  height: 39px;
  float: left;
  border: 1;
}

.inputfields h5 {
  display: inline-block;
  vertical-align: top;
  padding-top: 9px;
}

.sugglist {
  background-color: #fbfafa;
  position: absolute;
  /*top: 77px;
	z-index: 10;
	-webkit-box-shadow: 0px 0px 0px -1px #BDBDBD;*/
  padding: 10px;
  margin-right: 64px;
  z-index: 10;
}

.sugglist.sugglist-map {
  position: absolute;
  top: 50px;
  left: 90px;
}

.sugglist.sugglist-map.errormsg {
  color: red;
}

.tstbtn img {
  padding-top: 3px;
}

.sugglist-items {
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4em;
  background-color: #f7f7f7;
  margin-bottom: 4px;
  padding: 3px;
}

.sugglist-items:hover {
  color: blue;
  background-color: #f0ecec;
}

/* tabs for mobile */

.tabs {
  background-color: #ede7e7;
  height: 40px;
  position: relative;
  z-index: 1000;
  box-shadow: 0px 5px 7px -5px rgb(0 0 0 / 91%);
}

.tabbtn {
  height: 40px;
  width: 63px;
  border: none;
  margin-right: 1px;
}

/*#transport-car-btn {
  width: 40px;
background-color: #EFE9E9;
height: 40px;
position:relative;
cursor:pointer;
display:inline-block;
margin-right: 2px;
}

#transport-bicycle-btn {
  width: 40px;
  background-color: #EFE9E9;
  height: 40px;
  position:relative;
  cursor:pointer;
  display:inline-block;
}

#transport-car-btn img {
  position: absolute;
left: 50%;
top: 50%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}

#transport-bicycle-btn img {
  position: absolute;
left: 50%;
top: 50%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}*/

.radiusleft {
  -webkit-border-top-left-radius: 12px;
  -webkit-border-bottom-left-radius: 12px;
  -moz-border-radius-topleft: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.radiusright {
  -webkit-border-top-right-radius: 12px;
  -webkit-border-bottom-right-radius: 12px;
  -moz-border-radius-topright: 12px;
  -moz-border-radius-bottomright: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-left: 1px;
}

.linear-loader {
  position: absolute;
 /* bottom: -4px;*/
  z-index: 10000000000000;
  margin-top: 9px;
  width: 100%;
  margin-left: -13px;
}

.m-single {
  height: 34px;
}

.m-explore {
  height: 34px;
  margin-left: -1px;
}

.m-single:hover,
.m-explore:hover {
  background-color: #bfdb11;
}

.silentbtn {
  height: 33px;
  background-color: #e5ff44;
}

.submitmemory {
  background-color: #e8e50e;
  height: 31px;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
}

.submitmemory:hover {
  background-color: #dfff00;
}

.order-select {
  position: absolute;
  background-color: #e5ff44;
  cursor: pointer;
}

.order-select ul {
  list-style: none;
}

.order-select ul li p {
  padding: 4px 9px;
  font-size: 12px;
}

.order-select ul li:hover {
  background-color: #bfdb11;
}

#custom-autocomplete {
  padding: 0 10px !important;
}

.datafield.to.wide .react-autosuggest__input {
  width: 400px !important;
}

.datafield.from.wide .react-autosuggest__input {
  width: 400px !important;
}

.wrapper-special {
  position: relative;
  height: 100%;
  overflow: hidden;
  top: 0px;
}

.error-pane {
  top: 80px;
  position: fixed;
  height: 80px;
  background-color: aliceblue;
  z-index: 1000;
  width: 100%;
}

.error-msg {
  background-color: #7e7c7c;
  text-align: center;
  font-size: 18px;
  color: #fff;
  padding: 5px;
}

.loader {
  display: inline-block;
}

.loader.map {
  float: left;
}

.loader.front {
  float: right;
  margin-top: 24px;
}

.info-container {
  position: absolute;
  left: 62px;
  z-index: 900;
  background-color: rgba(0, 89, 123, 0.8392156862745098);
  top: 13px;
  width: 185px;
  height: 40px;
  padding: 10px;
  color: #f7f3f3;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #e5ff44;
}

.maploader {
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: #00000061;
}

.maploader-inner {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.error-container {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 900;
  background-color: rgba(0, 89, 123, 0.8392156862745098);
  width: 185px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e5ff44;
}

.error-container p {
  font-weight: normal;
  margin: auto;
  text-align: center;
  padding: 9px;
  color: #f7f3f3;
}

.error-container-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  color: #0279A5;
}

.freeze-overlap {
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: #00000061;
  -webkit-transition: background-color .5s;
  -moz-transition: background-color .5s ;
  -o-transition: background-color .5s;
  transition: background-color .5s ;
}



.container-wrapper {
  position: relative;
  width: 100%;
  /* height: calc(100vh - 126px);*/
 
}

.map-wrapper-max {
  position: absolute;
  width: calc(100% - 350px);
  background-color: #fff;
  float: left;
  height: 30%;
  z-index: 100;
  transition: height 0.5s ease-in-out;
  bottom: 0;
  left: 350px;
}

.map-wrapper {
  position: absolute;
  display: inline-block;
  background-color: #fff;
  height: 100%;
  right: 0;
  transition: width 0.5s ease-in-out;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  cursor: crosshair;
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-control-attribution {
  font-size: 10px;
}

.leaflet-bottom {
  z-index: 1000;
}

.leaflet-top {
  z-index: 900 !important;
}

.leaflet-popup-content-wrapper {
  padding: 0 !important;
  max-width: 200px;
}

.leaflet-popup-content {
  margin: 0 !important;
}

.leaflet-popup-content img {
  object-fit: cover;
  object-position: 50%;
  width: 100%;
  -webkit-border-top-left-radius: 12px;
  -webkit-border-top-right-radius: 12px;
  -moz-border-radius-topleft: 12px;
  -moz-border-radius-topright: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.leaflet-popup-content-inner {
  padding: 8px;
}

#enablefloat-btn {
  position: absolute;
  z-index: 10000;
  bottom: 10px;
}

.drawtools-btn {
  position: absolute;
  top: 10px;
  z-index: 1000;
  left: 10px;
  cursor: pointer;
}



.drawtools-route {
  /*position:relative;*/
}
.drawtools-route-transport-bar {
  margin-right: 0;
  float: left;
}

.drawtools-route-transport-item {
  margin-right: 4px;
  display: inline-block;
}

.search-tools {
  position: absolute;
  top: 10px;
  z-index: 800;
  left: 10px;
  cursor: pointer;
}


.searchtools-help {
  position: absolute;
  z-index: 500;
  top: 10px;
  left: 55px;
  border: 3px solid #a6a5a5;
  max-width: 53%;
  background-color: #fff;
  border-radius: 5px;
  padding: 2px 20px 4px 4px;
}

.searchtools-help-closed {
  z-index: 1000;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  left: 57px;
  top: 10px;
  color: #0279A5;
  width: 18px;
  height: 18px;
  background-color: #fff;
  padding-left: 9px;
  padding-bottom: 7px;
  border-radius: 20px;
  border: 1px solid #0279A5;
}

.searchtools-help-closed:hover {
  background-color: #E8E5E5;
}

.searchtools-help h3 {
  font-size: 16px;
  color: rgb(2, 121, 165);

  padding: 0;
}

.searchtools-help p {
  font-size: 13px;
  color: #000;
  padding: 0;
}

.pane-wrapper {
  z-index: 100;
  padding: 0;
  display: inline-block;
  float: left;
  height: 100%;
  overflow-y: hidden;
  width: 20%;
}

.pane-content {
  padding: 0;
  margin-top: 0px;
  overflow-y: scroll;
  height: calc(100% - 40px);
  position: absolute;
  width: 100%;
}

.pane-tools {
  background-color: #fcfafa;
  padding: 10px 15px;
  border-bottom: 1px solid #a9cdec;
}

.pane-tools label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 700;
  color: #4f4d4d;
}

.pane-header {
  z-index: 100;
  width: 100%;
  background-color: #c7c403;
  position: sticky;
  height: 40px;
}

.tool-section {
  margin-top: 15px;
}

.tool-section-left,
.tool-section-right {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.tool-section-right {
  float: right;
}

.tool-section-left h6 {
  margin-top: 6px;
}

.renderlist {
  padding: 15px;
}

.renderlist h2 {
  font-size: 25px;
  padding-bottom: 5px;
}

.renderlist .infoheader {
  margin: 5px;
}

.suggestions-to-field {
  display: block !important;
}

.suggestions-to-field.title {
  font-size: 18px;
}

.suggestions-to-field.country {
  font-size: 13px;
  color: #bbb;
}

.infoheader p {
  padding-bottom: 0;
}

.infoheader h4 {
  line-height: 1.4rem;
}

.pane-content-container {
  padding: 16px;
}

.detail-address {
  margin-bottom: 18px;
}

.detail-address h6 {
  font-size: 14px;
  color: #5b5a5a;
  margin-bottom: 0;
  letter-spacing: 0.5px;
}

.detail-coordinates {
  float: right;
  margin-bottom: 10px;
}

.detail-coordinates h6 span {
  letter-spacing: 0.2px;
}

.coords-prefix {
  padding: 3px 2px 3px 10px;
  font-size: 13px;
  color: #515151;
  background-color: #f4f1f1;
  -webkit-border-top-left-radius: 18px;
  -webkit-border-bottom-left-radius: 18px;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-bottomleft: 18px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.coords-content {
  padding: 3px 3px;
  font-size: 13px;
  background-color: #f4f1f1;
  color: #a85dc4;
}

.coords-copybtn {
  padding: 0px 5px 1px 4px;
  background-color: #dbd8db;
  -webkit-border-top-right-radius: 18px;
  -webkit-border-bottom-right-radius: 18px;
  -moz-border-radius-topright: 18px;
  -moz-border-radius-bottomright: 18px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  cursor: pointer;
}

.coords-copybtn:hover {
  background-color: #aaa9aa;
}

.taglist-item {
  display: inline-block;
  border: 1px solid #dcd8d8;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 6px;
  padding: 2px 6px;
  background-color: #f7fbff;
}

.detail-url {
  display: inline-block;
  padding-right: 10px;
}

.detail-url a {
  border: 1px solid #0390c4;
  padding: 4px 8px;
  color: #0390c4;
  background-color: #fff;
  border-radius: 7px;
  font-weight: 700;
  letter-spacing: 0.5px;
  transition: background-color 0.5s, color 0.5s;
}

.detail-url a:hover {
  background-color: #0390c4;
  color: #fff;
}

.icon {
  cursor: pointer;
  top: 8px;
}

.icon.close {
  position: absolute;
  right: 15px;
}

.icon.focus {
  position: absolute;
  left: 15px;
}

.paneA {
  position: relative;
  z-index: 600;
  background-color: #fff;
  box-shadow: 4px 0px 9px -3px #938e8e;
}

.paneB {
  position: absolute;
  transition: left 0.5s ease-in-out;
  background-color: #fff;
  z-index: 550;
  -webkit-box-shadow: 4px 0px 9px -3px #938e8e;
  box-shadow: 4px 0px 9px -3px #938e8e;
}

.paneBmax {
  position: absolute;
  width: calc(100vw - 367px);
  z-index: 101;
  box-shadow: 0px 4px 9px -3px #938e8e;
  transition: height 0.5s ease-in-out;
  background-color: #fff;
}

.pane-header-title {
  position: absolute;
  font-weight: 700;
  left: 22px;
  top: 6px;
  letter-spacing: 0.5px;
}

#pane-header-details {
  left: 22px;
}

#pane-header-route {
  left: 100px;
}

.pane-header-title span {
  color: #000;
  background-color: #fff;
  padding: 3px;
  border-radius: 12px;
  width: 21px;
  display: inline-block;
  text-align: center;
}

.pane-header-inner {
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: #c7c403;
  font-weight: 700;
  height: 32px;
  padding-top: 8px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.detailstabclose {
  background-color: #eeeeee;
  color: #059ed7;
  -webkit-box-shadow: inset 0px -5px 7px -7px #636161;
  box-shadow: inset 0px -5px 7px -7px #636161;
}

.routetabclose {
  background-color: #eeeeee;
  color: #059ed7;
  -webkit-box-shadow: inset 0px -5px 7px -7px #636161;
  box-shadow: inset 0px -5px 7px -7px #636161;
}

.infotabclose {
  background-color: #eeeeee;
  color: #0585b5;
  -webkit-box-shadow: inset 0px -5px 7px -7px #636161;
  box-shadow: inset 0px -5px 7px -7px #636161;
}

.detailstabclose:hover,
.routetabclose:hover,
.infotabclose:hover {
  background-color: #d7d3d3;
}

.transport-popdown {
  display: inline-block;
  vertical-align: top;
  margin-left: -1px;
  float: right;
}

.transport-select {
  background-color: #e8e50e;
  position: absolute;
  width: 50px;
  z-index: 10;
  height: 90px;
}

.transport-select ul {
  list-style-type: none;
  margin-left: 0;
}

.transport-select li {
  cursor: pointer;
  padding-left: 15px;
}

.transport-select li:hover {
  background-color: #bfdb11;
}

.btn-icon.left,
.btn-icon.right {
  display: inline-block;
}

.btn-icon.right {
  vertical-align: text-top;
}

.directions {
  margin-left: 7px;
}

.dir-fromto {
  margin-bottom: 24px;
}

.dir-fromto p {
  background-color: #059ed70d;
  padding: 10px;
  border-radius: 7px;
  color: #066689;
  font-size: 15px;
  margin-bottom: 8px;
}

.dir-fromto-label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: #6c6a6a;
  padding: 0;
}

.dir-info {
  margin: 12px 0;
  border-bottom: 1px solid #e0dddd;
  clear: both;
  padding: 16px 0 8px 0;
}

.dir-info-length {
  display: inline-block;
  width: 30%;
}

.dir-info-duration {
  display: inline-block;
  width: 70%;
}

/*.dir-strokeV:last-child {
  background-color: #fff;
}*/

.dir-item {
  position: relative;
}

.dir-instruction {
  margin-left: 25px;
}

p.dir-instruction {
  padding-bottom: 6px;
}

/* public transport */

.dir-instruction-pt {
}

.dir-item-pt {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dir-instruction-depart {
  width: 100%;
  margin-left: 20px;
  margin-bottom: 10px;
}

.dir-instruction-depart-text {
  width: 91%;
  display: inline-block;
}

.dir-instruction-depart-time {
  width: 20%;
  display: inline-block;
}

.dir-instruction-arrival {
  width: 100%;
  margin-left: 20px;
}

.dir-instruction-arrival-text {
  width: 80%;
  display: inline-block;
  padding-top: 17px;
}

.dir-instruction-arrival-time {
  width: 20%;
  display: inline-block;
}

.dir-strokeH-top-pt {
  position: absolute;
  top: 10px;
  height: 1px;
  width: 15px;
  background-color: transparent;
}

.dir-strokeH-bottom-pt {
  position: absolute;
  bottom: 20px;
  height: 1px;
  width: 15px;
  background-color: transparent;
}

.dir-strokeV-pt {
  position: absolute;
  height: 78%;
  width: 2px;
  left: 0;
  top: 10px;
}

.dir-bullet-top-pt {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  top: -5px;
  left: -4px;
}

.dir-bullet-top-inner-pt {
  width: 6px;
  height: 6px;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  top: -3px;
  left: -2px;
  z-index: 10;
}

.dir-bullet-bottom-pt {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  bottom: -16px;
  left: -4px;
}

.dir-bullet-bottom-inner-pt {
  width: 6px;
  height: 6px;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  top: 9px;
  left: -2px;
  z-index: 10;
}

.dir-bullet-single-pt {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #b3b30f;
  position: absolute;
  top: 5px;
  left: -4px;
}

.dir-instruction-arrow {
  display: inline-block;
  /*float: right;*/
  cursor: pointer;
}

.transport-select-btns {
  width: 40px;
  background-color: #efe9e9;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 2px;
  cursor: pointer;
}

.transport-select-btns:hover {
  background-color: #d2d1d1;
}

.pane-content-route-toolbar-left {
  display: inline-block;
  float: left;
}

.pane-content-route-toolbar-right {
  display: inline-block;
  float: right;
}

.transport-select-btns img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.directions {
  margin-left: 7px;
}

.dir-list {
  position: relative;
  margin: 10px;
  padding-top: 12px;
  clear: both;
}

.dir-strokeV {
  position: absolute;
  height: 100%;
  background-color: #059ed7;
  width: 1px;
  left: 0;
  top: 10px;
}

.dir-strokeV:last-child {
  background-color: #fff;
}

.dir-item {
  position: relative;
}

.dir-strokeH {
  position: absolute;
  top: 10px;
  height: 1px;
  width: 15px;
  background-color: #059ed7;
}

.dir-strokeH:before {
  content: "•";
  top: -10px;
  position: absolute;
  left: -1px;
  color: #059ed7;
}

.dir-instruction {
  margin: 0 0 0 25px;
}

p.dir-instruction {
  font-size: 14px;
}

.dir-instruction-transport {
  margin: 0 0 2px 25px;
  color: green;
}

/* scrollbar */

.pane-content {
  scrollbar-width: thin;
  scrollbar-color: #c2c2c0 #eeeded;
  -webkit-box-shadow: 4px 0px 9px -3px #938e8e;
  box-shadow: 4px 0px 9px -3px #938e8e;
}

.pane-content::-webkit-scrollbar {
  width: 12px;
  background: #fff;
}

.pane-content::-webkit-scrollbar-track {
  background: #fff;
}

.pane-content::-webkit-scrollbar-thumb {
  background-color: #d0cece;
}

.activity-wrapper {
  padding: 0 1%;
  display: inline-block;
  background-color: #a1ace8;
  float: left;
  height: 100%;
  -webkit-box-shadow: 4px 0px 9px -3px #938e8e;
  box-shadow: 4px 0px 9px -3px #938e8e;
  position: relative;
  z-index: 1000;
}

.chart-tooltip {
  margin: 10px;
}

.chart-tooltip p {
  padding: 0;
  font-size: 15px;
}

/* BLOG */

.form-input {
  background-color: #eee;
  padding-top: 20px;
  padding-bottom: 20px;
}

.image-overlay {
  height: 38px;
  background-color: rgba(133, 131, 131);
  position: relative;
  top: 0;
  width: 100%;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.image-overlay h6 {
  font-size: 14px;
  margin: 4px 0 0 10px;
  font-weight: 200;
  color: #fff;
  text-transform: uppercase;
}

.sr-tooltip {
  position: absolute;
  top: 38px;
  right: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
  background-color: rgba(255, 255, 255, 0.9);
}

.sr-tooltip.active {
  height: 140px;
}

.sr-tooltip p {
  margin: 10px;
  color: #000;
  font-size: 14px;
  padding-bottom: 0;
}

.sfversion {
  position: relative;
  cursor: pointer;
  float: right;
  margin: 10px;
  background-color: #a85dc4;
  padding: 2px 4px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
}

/* Tooltip text */
.sfversion .sfversion-tooltip {
  visibility: hidden;
  height: 48px;
  width: 200px;
  background-color: #6e6b6b;
  color: #fff;
  text-align: left;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  z-index: 10000;
  position: absolute;
  top: -5px;
  right: 105%;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.sfversion:hover .sfversion-tooltip {
  visibility: visible;
}

.transportbar {
  position: absolute;
  top: 38px;
  left: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
  background-color: rgba(0, 0, 0, 0.7);
}

.transportbar.active {
  height: 38px;
}

.card-item-header-sub-btn {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-right: 1px;
}

.wikilink {
  position: absolute;
  right: 5px;
  color: #1fb6cf;
}

.wikilink p {
  font-size: 0.9em;
}

.wikilink-list {
  top: -4px;
}

.wikilink-singleitem {
  bottom: -31px;
}

/* GRID & CARD********************************************/

.card-item {
  width: 100%;
  padding: 2%;
  margin: 0 2% 14px 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  transition: box-shadow 0.5s, -webkit-box-shadow 0.5s;
}

.card-item h5 {
  overflow-wrap: break-word;
  color: #059dd6;
  letter-spacing: 0.2px;
  font-size: 19px;
  display: inline-block;
  cursor: pointer;
}

.card-item h5:hover {
  color: #055c7c;
}

.card-item-image {
  cursor: pointer;
}

.card-item:hover {
  -webkit-box-shadow: 0px 3px 15px 2px #c2c2c2;
  box-shadow: 0px 3px 15px 2px #c2c2c2;
}

.card-item-img-cover {
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  object-fit: cover;
  object-position: 50%;
  width: 100%;
}

.card-item-content {
  position: relative;
}

.card-item-content h6 {
  color: #4b4a4a;
}

.card-item-header-container.left {
  float: left;
  height: 38px;
}

.card-item-header-container.right {
  float: right;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
}

.card-item-header-btn {
  height: 38px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

#savebtn {
  width: 38px;
  border-top-left-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  background-color: rgba(0, 0, 0, 0.7);
  margin-right: 1px;
}
#zoombtn {
  width: 38px;
  background-color: rgba(0, 0, 0, 0.7);
  margin-right: 1px;
}

#routebtn {
  width: 38px;
  background-color: rgba(0, 0, 0, 0.7);
  margin-right: 1px;
}

#graphbtn {
  background-color: rgba(0, 0, 0, 0.7);
  margin-right: 1px;
}

.card-item-header-btn.curve-left {
  border-top-left-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
}

.card-item-header-btn.curve-right {
  border-top-right-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
}

.user-rating-section,
.detail-memory-rating {
  display: inline-block;
  border-bottom: 1px solid #aaa;
  width: 100%;
}

.s-rate {
  float: right;
  padding: 0 8px;
  display: none;
}

.s-rate-absolute {
  padding: 6px 6px 1px 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  background-color: rgba(0, 0, 0, 0.7);
}

.s-rate-absolute h6 {
  display: inline-block;
  margin-left: 3px;
}

.e-rate {
  float: right;
  padding: 0;
  top: 4px;
}

.item {
  background: transparent;
  display: inline-block;
  margin: 0 0.4% 10px 0.4%;
  width: 15.7%;
  height: 600px;
  transition: 0.3s;
  border-radius: 10px;
  vertical-align: top;
  position: relative;
}

.row {
  margin-bottom: 15px;
}

.item:hover {
  -webkit-box-shadow: 0px 0px 13px 0px #a6a6a6;
  box-shadow: 0px 0px 13px 0px #a6a6a6;
}

.img-item {
  height: 250px;
}

.img-item img {
  max-width: 100%;
  object-fit: cover;
  object-position: 50%;
  height: 250px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottom-ref {
  position: absolute;
  bottom: 0;
}

.getmore {
  position: fixed;
  bottom: 0;
  z-index: 10000;
}

.page-container {
  margin-top: -144px;
  position: relative;
}

.item-wrapper {
  padding: 35px 7%;
  background-color: #fff;
  margin-top: 50px;
  border-radius: 16px;
}

.item-wrapper ul {
  padding-left: 30px;
}

.content-container {
  height: 317px;
  padding: 15px;
  background-color: #eeeeee5e;
  border: 1px solid #ece9e9;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.content-container h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.content-container h5 {
  margin-bottom: 15px;
  color: #282828;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.4px;
}

.img-container {
  position: relative;
  line-height: 0;
}

.img-container h6 {
  position: absolute;
  background: transparent;
  color: #fff;
  padding-left: 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-shadow: 1px 1px #000;
}

.img-container .img-item img {
  background-color: transparent;
  height: 250px;
  max-width: 100%;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.img-topbar {
  position: absolute;
  top: 0;
  height: 40px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.detail-rating-section {
  display: inline-block;
  width: 100%;
  position: relative;
}

/* memories list in details and item pages */

.provider-container {
  background-color: #66a1b7;
  padding: 10px;
  color: #fff;
}

.detail-title h3 {
  color: #034e6a;
}

/* detail memory */

.detail-memory-heading h3 {
  margin-bottom: 3rem;
  border-bottom: 1px solid #aaa;
}

.review-section {
  border: 1px solid #02c1e4;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 10px 30px 10px;
  background-color: transparent;
  position: relative;
}

.review-section ul {
  list-style: none;
}

.detail-memory-avatar {
  position: absolute;
  top: -19px;
  left: -11px;
  z-index: 20;
}

.detail-memory-username {
  position: absolute;
  background-color: #fff;
  z-index: 10;
  top: -15px;
  padding-left: 24px;
  width: 195px;
  border-radius: 10px;
  left: 15px;
  border: 1px solid #02c1e4;
}

.detail-memory-username p {
  color: #3f3e3e;
  font-weight: bold;
  padding-bottom: 2px;
}

.detail-memory-header {
  border-bottom: 1px solid #e6e0e0;
  padding: 10px;
}

.detail-memory-rating {
  border-bottom: 1px solid #aaa;
}

.detail-memory-title {
  padding: 10px;
}

.detail-memory-date {
  text-transform: uppercase;
  color: #014a57;
  font-size: 1.4rem;
  letter-spacing: 1px;
  display: inline-block;
}

.detail-memory-footer {
  padding-top: 10px;
  /*display:flex;*/
  margin: 25px 0;
}

.detail-memory-footer-content p {
  display: inline-block;
  margin-right: 10px;
  padding-top: 4px;
}

.detail-memory-footer-content ul {
  list-style: none;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 286px [col-start]);
  grid-gap: 20px;
}

.grid-item {
  position: relative;
}

.grid-item-section-top-left {
  float: left;
}

.grid-item-section-top-right {
  float: right;
  display: flex;
}

.grid-item-section-top-left h6 {
  color: #70716e;
  font-weight: 700;
}

.content-container a h3 {
  color: #059dd6;
  font-size: 25px;
}

.grid-item-footer {
  position: absolute;
  bottom: 0;
  height: 80px;
}

.loadMoreFooter {
  position: fixed;
  bottom: 0;
  background-color: rgba(100, 100, 100, 0.6);
  height: 60px;
}

.loadMoreFooter button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* USER PAGE */

.top-section {
  position: relative;
  margin: 30px 0 9px 0;
}

.top-section h3 {
  display: inline-block;
  color: #514e4e;
}

.top-section img {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 8px;
}

.left-pane {
  border-radius: 10px;
  display: inline-block;
  width: 49%;
  padding: 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-right: 2%;
  margin-bottom: 20px;
  vertical-align: top;
}

.trip-container {
  border-bottom: 1px solid #aaa;
}

.trips-heading {
  width: 100%;
  background-color: #059dd6;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 16px;
  height: 50px;
}

.trips-heading h4 {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  float: left;
  padding: 0;
  font-size: 23px;
}

.trips-createbtn {
  display: inline-block;
  float: right;
  cursor: pointer;
}

.trips-content {
  padding: 0px;
}

.trips-content ul {
  margin-left: 0;
}

.trips-content p span {
  color: #059ed7;
  text-decoration: none;
  cursor: pointer;
}

.trips-content p span:hover {
  color: #03465f;
}

/* inside trips */

.trip-heading-upper {
  position: relative;
  height: 37px;
  padding-top: 10px;
}

.trip-heading-lower {
  position: relative;
  height: 37px;
  padding: 3px 0 8px 0;
  margin-bottom: 16px;
}

.btns {
  position: absolute;
  right: 13px;
  top: 11px;
}

.btns img {
  margin-left: 5px;
  cursor: pointer;
}

.trip-title {
  position: absolute;
  left: 13px;
}

.trip-title h4 {
  color: #059dd6;
  font-weight: bold;
  display: inline-block;
  vertical-align: super;
  cursor: pointer;
  font-size: 21px;
}

.trip-title h4:hover {
  color: #034e6a;
}

.trip-date {
  position: absolute;
  left: 13px;
}

.trip-date h5 {
  color: #353434;
  font-weight: bold;
  line-height: 18px;
}

.trip-heading.btns {
  float: right;
}

.trip-content {
  padding: 0;
}

.item-header-row1 {
  display: inline-block;
  border-bottom: 1px solid #aaa;
  width: 100%;
  position: relative;
}

.item-header-left h6 {
  font-weight: bold;
  color: #242323;
  letter-spacing: 1px;
}

.item-header-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.item-header-right p {
  padding: 0;
}

.item-header-right p {
  display: inline-block;
}

.item-header-row2 {
  display: inline-block;
  width: 100%;
}

.item-header-row2-left {
  float: left;
}

.item-title {
  position: absolute;
  color: #fff;
  top: -50px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
}

.item-title h1 {
  letter-spacing: 0.5px;
}

.item-e-rate {
  float: right;
  padding: 20px 0 0 20px;
}

.item-header-row2-right {
  display: flex;
  float: left;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 10px;
}

.item-btn-savetrip {
  margin-right: 10px;
}

.item-main {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.item-leftcol {
  position: relative;
  width: 40%;
}

.item-rightcol {
  width: 60%;
}

.item-image {
  /*float: left;
	width: 40%;*/
}

.item-content {
  /*width: 60%;*/
  padding: 0 20px;
}

.item-content h6 {
  color: #059ed7;
  cursor: pointer;
}

.item-content h6:hover {
  color: #03465f;
}

p.popupText {
  border: 1px solid #d6d4d4;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  padding: 10px;
  font-size: 15px;
}

.trip-item {
  position: relative;
  background-color: #f2f2f2;
  margin-bottom: 13px;
  width: calc(100% - 2px);
  overflow: hidden;
  margin-right: 1px;
}

.trip-item-img {
  width: 21%;
  float: left;
  display: inline-flex;
}

.trip-item-img img {
  min-height: 88px;
}

.trip-item-content {
  width: 70%;
  float: left;
  padding: 10px;
}

.trip-item-title h5 {
  margin: 0;
  font-weight: bold;
  font-size: 1.3rem;
  display: inline-block;
}

.trip-item-title a h5 {
  color: #049bd3;
}

.trip-item-title a h5:hover {
  color: #034e6a;
}

.trip-item-deletebtn {
  position: absolute;
  right: 7px;
  top: 12px;
  cursor: pointer;
}

.text-blank-profile {
  text-align: center;
  width: 50%;
  margin: 20px auto;
}

.right-pane {
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 20px;
}

.mem-container {
  display: inline-block;
  width: 100%;
  margin-bottom: 13px;
  border-bottom: 1px solid #e0dede;
}

.mem-location {
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.mem-title {
  width: 100%;
}

.mem-title h5 {
  font-weight: bold;
  font-size: 1.6rem;
  display: inline-block;
  vertical-align: top;
}

.mem-title a h5 {
  color: #049bd3;
}

.mem-title h5:hover {
  color: #034e6a;
}

.mem-cat h5 {
  font-size: 13px;
  margin-left: 0;
  font-weight: bold;
  letter-spacing: 0.1px;
}

.popupbtns {
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: -5px;
}

.btn-activetrip {
  top: 18px;
  left: -32px;
}

.mem-img {
  width: 30%;
  display: inline-flex;
}

.mem-img img {
  height: 164px;
  max-width: 100%;
  object-fit: cover;
  object-position: 50%;
  width: 273px;
}

.mem-date h5 {
  font-weight: bold;
}

.mem-content {
  width: 66%;
  float: left;
  position: relative;
  padding: 2%;
}

.mem-btns {
  position: absolute;
  right: 10px;
  top: 30px;
}

.mem-btns img {
  cursor: pointer;
}

.mem-comment {
  display: inline-block;
}

.mem-comment p {
  font-size: 18px;
}

/* modals */

.dates {
  display: block;
}

.dates-from {
}

/* how it works */

ul.liststylenone {
  list-style: none;
}

ul.listindent {
  margin-left: 23px;
}

/* Donate */

#donate-button {
  text-align: center;
}

@keyframes fade {
  0% {
    opacity: 0;
    width: 20px;
    height: 20px;
  }

  20% {
    opacity: 1;
    width: 40px;
    height: 40px;
  }

  100% {
    opacity: 1;
    width: 32px;
    height: 32px;
  }
}

.blinking {
  animation: fade 1s alternate;
}

/* new my trips */

.trip-locations {
  margin: 0 16px;
  padding: 20px;
  border-radius: 4px;
  background-color: #fffdfd;
}

.trip-locations-header {
  padding: 0;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.trip-locations-content {
  padding: 10px;
}

.db-full-width-container {
  background-color: #fff;
  border-radius: 10px;
  margin: 0 0 25px 0;
}

.db-left-pane-container {
  width: 49%;
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-right: 2%;
  margin-bottom: 20px;
  vertical-align: top;
  background-color: #fff;
}

.db-right-pane-container {
  width: 49%;
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 20px;
  background-color: #fff;
}

.db-createtrip-btn {
  background-color: #0583ca;
  color: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 22px;
  border: none;
  border-radius: 6px;
  font-size: 18px;
}

.db-container-header {
  display: inline-block;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.db-container-header h3 {
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Magra-Bold", sans-serif;
  font-size: 20px;
  letter-spacing: 0.2px;
  color: #9e9b9b;
  padding: 18px 0 0 26px;
}

.db-container-content {
  padding: 28px;
  border-radius: 10px;
}

.db-trip {
  background-color: #e4e4e4;
  margin-bottom: 16px;
  border-radius: 6px;
  margin-left: 30px;
  padding-bottom: 1px;
}

.db-trip-header {
  position: relative;
  padding: 14px 22px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.db-trip-header h4 {
  font-family: "Magra-bold", sans-serif;
  font-size: 20px;
  color: #0583ca;
  padding-right: 84px;
  cursor: pointer;
}

.db-trip-header h4:hover {
  color: #04669d;
}

.db-trip-header.activetrip h4 {
  font-size: 25px;
}

span.db-trip-title-postfix {
  color: #33b605;
  font-size: 0.8em;
  padding-left: 6px;
}

p.db-trip-date {
  color: #888686;
  font-weight: 700;
  padding-bottom: 5px;
}

.db-trip-header.activetrip p.db-trip-date {
  font-size: 20px;
}

p.db-trip-description {
  color: #555353;
  font-size: 15px;
}

.db-trip-content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 7px;
}

.db-trip-content p {
  padding-bottom: 4px;
}

.btn-group-trips {
  position: absolute;
  right: 18px;
  top: 18px;
}

.btn-group-events {
  position: absolute;
  right: -3px;
  top: -25px;
}

.trip-location-img {
  /* width: 25%;*/
  display: inline-block;
}

.trip-location-img img {
  object-fit: cover;
  object-position: 50%;
  height: 136px;
  width: 260px;
}

.trip-locations-content {
  width: 75%;
  display: inline-block;
  vertical-align: top;
}

.trip-locations.activetrips .trip-location-img {
  width: 18%;
}

.trip-locations.activetrips .trip-location-img img {
  height: 125px;
}

.trip-locations.activetrips .trip-location-container {
  height: 125px;
}

.trip-locations-content-event {
  border-bottom: 1px dashed #328abb;
}

.trip-locations-content-event h2 {
  color: #696767;
  font-size: 2rem;
}

.trip-locations-content-location {
  position: absolute;
  bottom: 0;
}

.trip-locations-content h4 {
  font-family: "Magra-bold", sans-serif;
  color: #0583ca;
  font-size: 19px;
}

.trip-locations-content h4:hover {
  color: #04669d;
}

.trip-locations-content p.trip-location-category {
  font-family: "Magra-bold", sans-serif;
  color: #33b605;
}

.trip-locations-content p.trip-location-country {
  color: #292929;
}

.trip-locations-memory {
  margin-top: 0px;
  padding: 10px;
  border-top: 1px solid #c1c0c0;
}

.trip-location-container {
  position: relative;
  background-color: #f9f8f8;
  border: 1px solid #e6e6e6;
}

.trip-locations-header h4 {
  font-family: "Magra-bold", sans-serif;
  color: #5e5d5d;
  font-size: 19px;
}

.trip-locations-header p {
}

.memory-button {
  text-decoration: underline;
  text-align: right;
  color: #0583ca;
  padding-top: 12px;
  cursor: pointer;
}

.memory-button:hover {
  text-decoration: none;
}

.taglist {
}

.taglist li {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  border: 1px solid #0583ca;
  padding: 5px 10px;
  border-radius: 16px;
  background-color: #f9f8f8;
}

/* dev changes */

.recharts-text,
text.customtick {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
