@media screen and (min-width: 500px) and (max-width: 613px) {
  .logo {
    width: 200px;
    height: 26px;
    background: url("./assets/silencespotterlogoMob2.png") no-repeat;
    left: 18%;
    margin-top: 21px;
    background-size: contain;
    transform: initial;
  }
}

@media screen and (min-width: 500px) and (max-width: 990px) {
  .menu-item {
    padding-top: 22px;
  }

  #planner-item span,
  #location-item span,
  #info-item span,
  #donate-item span {
    display: none;
  }

  .menu-item .icon-solid {
    padding-right: 6px;
  }

  #info-item svg,
  #donate-item svg {
    font-size: 19px;
  }

  .remove {
    display: none;
  }

  .menu-item-outerleft {
    border-right: 1px solid #5a5858;
  }

  .leftmenu a {
    font-size: 20px;
    padding-left: 9px;
  }

  .menu-item-outerright {
    border-left: 1px solid #5a5858;
  }

  .rightmenu a {
    margin: 0 19px;
  }

  .logo {
    transform: initial;
    left: 80px;
  }
}

@media screen and (max-width: 499px) {
  #planner-item span,
  #location-item span,
  #info-item span,
  #donate-item span {
    display: none;
  }

  .logo {
    width: 120px;
    height:17px;
    background: url("./assets/silencespotterlogoMob.png") no-repeat;
    left: 48px;
    top:6px;
    background-size: contain;
    transform: initial;
    
  }

  .remove {
    display: none;
  }

  .menu-item-outerleft {
    border-right: 1px solid #5a5858;
  }

  .leftmenu a {
    font-size: 16px;
  }

  .menu-item .icon-solid {
    /*padding-right: 6px;*/
  }

  .menu-item-outerright {
    /*width: 30px;*/
  }

  .menu-item-outerright {
    border-left: 1px solid #5a5858;
  }

  .menu-container {
    height: 40px;
    width: 50px;
  }

  .menu-container .btn-large {
    top: 43%;
  }

  .leftmenu a {
    padding-left: 0;
    padding-right: 0;
    margin: 0 13px;
  }

  .rightmenu a {
    margin: 0 14px;
  }

  .icon-solid {
    padding: 0;
  }

  .rightmenu {
    right: 0;
    margin: 0;
  }

  .header {
    height: 40px;
  }

  .logo {
    margin-top: 6px;
  }

  .menu-item {
    padding-top: 11px;
  }

  #user-menu {
    top: 52px;
  }
}

@media screen and (max-width: 364px) {
  .logo {

    width: 120px;
    height:17px;
    background: url("./assets/silencespotterlogoMob.png") no-repeat;

    background-size: contain;
    left: 48px;
    top:6px;
    transform: initial;
    margin-top: 6px;
  }
}
