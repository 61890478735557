@media screen and (min-width: 365px) and (max-width: 499px) {
  p,
  ul li {
    font-size: 16px;
  }

  .wrapper {
    padding-top: 40px;
  }

  .wrapper-special {
    height: 100vh;
    max-height: -webkit-fill-available;
  } 
  
  .remove {
    display: none;
  }

  #heroimage {
    height: calc(100vh - 40px);
  }

  .menu-item-outerleft {
    border-right: 1px solid #5a5858;
  }

  .leftmenu a {
    /* font-size: 20px;*/
    padding-left: 0px;
  }

  .menu-item-outerright {
    border-left: 1px solid #5a5858;
  }

  .rightmenu a {
    margin: 0 19px;
  }

  .item {
    margin: 0 0.4% 10px 0.4%;
    width: 47%;
  }

  /* FRONT PAGE STUFF ************************************/

  #highlights h2,
  #FPHelp h2 {
    font-size: 46px;
  }

  .fp-help-item p {
    font-size: 19px;
  }

  .fp-syn-item h2 {
    font-size: 29px;
    top: 8%;
    left: 35%;
  }

  .fp-syn-container-sub {
    width: initial;
    padding: 20px;
  }

  .wa-features {
    padding: 0;
  }

  input#searchsubmit {
    position: absolute;
    right: 16px;
  }

  .container-wrapper {
    /*height: calc(100vh - 242px);*/
  }

  .paneA,
  .paneB {
    z-index: 10020000;
  }

  .directions-container {
    position: absolute;
    bottom: 8px;
    left: 6px;
  }

  #counting {
    padding: 43px 0 19px 0;
  }

  #counting h1 {
    line-height: 1.7;
    padding: 0px 14px;
  }

  .item-leftcol {
    width: 100%;
  }

  .item-rightcol {
    width: 100%;
  }

  .item-header-right {
    position: initial;
  }

  .route-input {
    height: 126px;
    box-shadow: none;
    margin-top: 48px;
  }

  .linear-loader {

    margin-left:0;
    top:-9px;
  }

  .from-container {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    margin-left: 0;
  }

  input#searchsubmit {
    position: absolute;
    right: 16px;

  }

  .fp-help-intro {
    width:92%;
  }

  .to-container {
    display: block;
    width: auto;
    margin: 0;
  }

  #setgethome {
    margin-top: 54px;
  }

  .datafield h5 {
    margin-right: 21px;
  }

  /* user page */

  .db-left-pane-container {
    display: block;
    width: 100%;
  }

  .db-right-pane-container {
    display: block;
    width: 100%;
  }

  .db-trip-header h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .db-trip-header.activetrip h4 {
    font-size: 20px;
  }

  .btn-group-trips {
    position: initial;
  }

  .pane-header {
    width: 100%;
  }

  #highlights h2 {
    font-size: 44px;
  }

  .help-features {
    padding-bottom: 0;
  }

  .hl-container h3 {
    margin-left: 0;
    font-size: 30px;
  }
  .hl-container span {
    margin-left: 0;
    font-size: 23px;
    display: block;
  }

  img.arrow-left {
    position: absolute;
    z-index: 1000;
    left: 0;
  }

  img.arrow-right {
    position: absolute;
    z-index: 1000;
    right: 0;
  }

  #FPHelp h2 {
    font-size: 44px;
  }

  .fp-syn-container-sub {
    width: initial;
  }

  .fp-help-item p {
    font-size: 20px;
  }

  .item-main {
    display: block;
    width: 100%;
  }

  .item-image {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  .item-content {
    width: 100%;
    display: block;
    padding: 0;
  }

  /* footer */

  .footer-inner-right {
    float: left;
    text-align: left;
    width: 100%;
  }

  .footer-wrapper {
    height: 76px;
  }

  .footer-container {
    padding-top: 22px;
  }

  .hero-form h2 {
    font-size: 37px;
  }

  .filters-activity {
    width: 100%;
  }

  .filters-category {
    width: 100%;
    margin-top: 8px;
  }

  .tools-loadmore {
    margin-right: 0;
    position: absolute;
    /* top: 152px;*/
    right: 0;
  }

  .tstbtn.loadmore.radiusleft {
    -webkit-border-radius: 0;
    -moz-border: 0;
    border-radius: 0;
    height: 40px;
  }

  .tstbtn.reset-btn.radiusright {
    -webkit-border-radius: 0;
    -moz-border: 0;
    border-radius: 0;
    height: 40px;
  }

  #head-1,
  #head-2 {
    display: none;
  }

  #notfound-container {
    width: 100%;
  }

  .notfound-btn {
    width: 40%;
    height: 116px;
  }
}
