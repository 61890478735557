@media screen and (min-width: 990px) and (max-width: 1120px) {
  p,
  ul li {
    font-size: 18px;
  }

  .item {
    margin: 0 0.4% 10px 0.4%;
    width: 24%;
  }

  .wrapper-special {
    height: 100vh;
    max-height: -webkit-fill-available;
  } 

  .linear-loader {
    margin-left: 0;
    top: -9px;
  }

  input#searchsubmit {
    position: absolute;
    right: 16px;
  }


  .fphlp-btn {
    position: inherit;
    margin-top: 16px;
  }
  
  #datafield-from {
  }

  .tstbtn.loadmore.radiusleft {
    -webkit-border-radius: 0;
    -moz-border: 0;
    border-radius: 0;
    height: 40px;
  }

  .from-container {
    margin: 0 0 8px 0;
    width: 100%;
  }

  .tool-section-right {
    display: block;
    width: 100%;
    float: initial;
    margin-top: 10px;
  }

  .tool-section-left {
    width: 100%;
  }

  .tool-loadmore {
    display: inline-block;
  }

  .tool-section-left h6 {
    display: inline-block;
    vertical-align: bottom;
    /* padding-left: 10px;*/
  }

  .tools-loadmore {
    margin-right: 0;
    position: absolute;
    /* top: 152px;*/
    right: 0;
  }

  .tstbtn.reset-btn.radiusright {
    -webkit-border-radius: 0;
    -moz-border: 0;
    border-radius: 0;
    height: 40px;
  }

  .filters-category,
  .filters-activity {
    width: 49%;
  }

  .filters-category {
    float: right;
    margin-right: 0;
  }

  .paneA,
  .paneB {
    z-index: 10020000;
  }
}
